<template>
    <div class="page-warp container-page">
        <div class="scroll-box">
            <van-cell 
                @click="toDetail(item)" 
                class="account-item cell-item-comm" center 
                v-for="(item,inx) in loopList" :key="inx">
                <template #title>
                    <span class="custom-title">{{item.customerName}}</span>
                </template>
                <template #label>
                    <div class="custom-title">开票总额度：{{item.invoiceAmount}}元</div>
                    <div class="custom-title">剩余额度：{{item.invoiceAmountBalance}}元</div>
                    <div class="custom-title">剩余开票数：{{item.invoiceCount}}张</div>
                    <div class="custom-title">服务费比例：{{item.customerServiceFeeRateStr}}</div>
                    <div class="right-middle-icon" v-if="item.invoiceCount > 0 && item.invoiceAmountBalance > 0">
                        <van-tag type="primary" style="float:right;">去开票</van-tag>
                    </div>
                </template>
            </van-cell>
            <infinite-loading 
                v-if="!finished" 
                @infinite="onLoad"
                ref="infiniteLoading">
            </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
        </div>
    </div>
</template>
<script>
import { pageQuery } from '@/api/customer'
import InfiniteLoading from "vue-infinite-loading";
export default {
    name:'customer',
    components: {
        InfiniteLoading
    },
    data() {
        return {
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
        };
    },
    created(){
        sessionStorage.removeItem('invoiceDetail');
        sessionStorage.removeItem('invoiceSubForm');
    },
    methods: {
        onLoad($state) {
            // 异步更新数据
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            if(this.active){
                pramas.commissionStatus = this.active;
            }
            this.loading = true;
            pageQuery (pramas).then(res=>{
                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1;
                this.loading = false;
                
                if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
            })
        },
        toDetail(row){
            if(row.invoiceCount > 0 && row.invoiceAmountBalance > 0){
                sessionStorage.setItem('invoiceDetail',JSON.stringify({id:row ? row.id : '',customerServiceFeeRate:row ? row.customerServiceFeeRate : '',title:row.customerName?row.customerName:''}))
                this.$router.push({
                    path:'/toInvoice',
                })
            }
        }
    },
};
</script>
<style lang="less" scope>
.account-item {
    .custom-title {
        margin-right: 5px;
    }
    .ctrl-right { 
        color: #080808;
    }
}
</style>