/*
 * @Description: 企业
 */
import {get, postJSON } from '@/utils/request'

export function pageQuery(data) {
    return get('/system/customer/individualList', data)
}
export function findById(data) {
    return get('/system/area/' + data)
}
// 接受订单
export function takeOrder(data) {
    return get('/order/takeOrder', data)
}
// 拒绝订单
export function rejectOrder(data) {
    return get('/order/rejectOrder', data)
}
// 企业开票订单
export function rejectIndividualInvoi(data) {
    return postJSON('/system/individualInvoice/create', data)
}
// 单位名称
export function searchBusinessQuery(data) {
    return get('/system/individualInvoice/businessQuery', data)
}